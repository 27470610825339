export const calcDiscount = (
  itemPrice: number,
  maxItemPrice: number | undefined,
  packageType: string
): number | undefined => {
  if (!maxItemPrice) return undefined

  let price = maxItemPrice
  if (packageType === 'ANNUAL') {
    price = itemPrice / 12
  }
  if (packageType === 'THREE_MONTH') {
    price = itemPrice / 3
  }

  const rawDiscount = ((maxItemPrice - price) / maxItemPrice) * 100
  return Math.floor(rawDiscount)
}