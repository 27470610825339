import * as React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import PricingHeroCard from '../components/PricingHeroCard'
import PlanCard from '../components/PlanCard'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const ImageWrap = styled.div`
  display: flex;
  padding: 40px;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const MAX_NUMERIC_PRICE = 5.99

const MonthlyPlan: React.FC = () => (
  <PlanCard
    visibleCost={{
      dollars: 5,
      cents: 99
    }}
    annualCost={{
      cents: 99,
      dollars: 71
    }}
    monthlyCost={{
      dollars: 5,
      cents: 99
    }}
    description="Pay once a month"
    image="/static/pricing/Vision.png"
    imageComponent={
      <ImageWrap>
        <StaticImage
          style={{ width: '80%' }}
          src="../../static/images/pricing/Vision.png"
          alt="My School Files"
        />
      </ImageWrap>
    }
    numericPrice={5.99}
    maxNumericPrice={MAX_NUMERIC_PRICE}
    packageType="MONTHLY"
    title="Monthly Plan"
  />
)

const QuarterlyPlan: React.FC = () => (
  <PlanCard
    visibleCost={{
      dollars: 14,
      cents: 99
    }}
    annualCost={{
      cents: 96,
      dollars: 59
    }}
    monthlyCost={{
      dollars: 4,
      cents: 99
    }}
    description="Pay every three months"
    image="/static/pricing/Notes.png"
    imageComponent={
      <ImageWrap>
        <StaticImage
          style={{ width: '80%' }}
          src="../../static/images/pricing/Notes.png"
          alt="My School Files"
        />
      </ImageWrap>
    }
    packageType="THREE_MONTH"
    title="3 Month Plan"
    numericPrice={14.99}
    maxNumericPrice={MAX_NUMERIC_PRICE}
    savings={12}
  />
)

const AnnualPlan: React.FC = () => (
  <PlanCard
    visibleCost={{
      dollars: 47,
      cents: 99
    }}
    annualCost={{
      cents: 99,
      dollars: 47
    }}
    monthlyCost={{
      dollars: 3,
      cents: 99
    }}
    description="Pay once a year"
    imageComponent={
      <ImageWrap>
        <StaticImage
          style={{ width: '80%' }}
          src="../../static/images/pricing/Woman_Scientist.png"
          alt="My School Files"
        />
      </ImageWrap>
    }
    image="/static/pricing/Woman_Scientist.png"
    packageType="ANNUAL"
    numericPrice={47.99}
    maxNumericPrice={MAX_NUMERIC_PRICE}
    title="Annual Plan"
    savings={24}
  />
)

const PricingPage = () => (
  <Layout>
    <Seo title="Pricing" url="https://myschoolfiles.com/pricing" />
    <PricingHeroCard showAllPlansButton={false} />
    <MonthlyPlan />
    <QuarterlyPlan />
    <AnnualPlan />
  </Layout>
)

export default PricingPage
