import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, H1, MediaQuery } from '../styles'
import ButtonSignup from './ButtonSignup'
import DownloadButtons from './DownloadButtons'
import Price from './Price'
import ButtonFreeTrial from './ButtonFreeTrial'
import { calcDiscount } from '../utils/math'

interface Cost {
  dollars: number
  cents: number
}

const Card = styled.div`
  display: flex;
  background-color: #fff;
  flex: 1;
  max-width: 1920px;
  margin: 0 auto;
  flex-direction: column;

  padding: 0 0;
  /* margin: 2px 0; */
  border-bottom: solid 2px #dcdcdc;
  /* box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2); */
  @media ${MediaQuery.Large} {
    flex-direction: row;
    padding: 100px 0;
  }
`

const ImageWrap = styled.div`
  display: flex;
  padding: 40px;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  width: 80%;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  .content-wrap {
    text-align: center;
    width: 100%;
  }
`

const StyledH1 = styled(H1)`
  color: ${Colors.darkBlue};
  text-align: center;
`

const MonthlyPrice = styled(H1)`
  position: relative;
  display: inline-block;
  color: ${Colors.black};
  font-size: 5rem;

  margin: 10px 0 0 0;
  padding: 0;
  line-height: 100%;

  @media ${MediaQuery.Medium} {
    font-size: 10rem;
    margin: 40px 0 0 0;
  }

  sup.primary {
    position: absolute;
    top: -1.5rem;
    left: -1.5rem;
    font-size: 2rem;
    vertical-align: text-top;
    @media ${MediaQuery.Medium} {
      top: -3rem;
      left: -3rem;
      font-size: 4rem;
    }
  }
`

const FreeTrialWrap = styled.div`
  margin: 30px 0 0 0;
`

const MonthlyPriceSlash = styled(MonthlyPrice)`
  /* :before {
    position: absolute;
    width: 100%;
    content: '';
    left: 0%;
    top: 40%;
    right: 0;
    border-top: 5px solid;
    border-color: red;
    transform: rotate(45deg);
    z-index: 0;
    @media ${MediaQuery.Medium} {
      border-top: 10px solid;
      border-color: red;
      top: 45%;
    }
    @media ${MediaQuery.Large} {
      top: 40%;
    }
  } */
`

const DiscountedMonthlyPrice = styled(H1)`
  position: absolute;
  display: inline-block;
  color: ${Colors.black};
  font-size: 3rem;
  margin: 0px 0 0 20px;
  padding: 0;
  line-height: 100%;
  @media ${MediaQuery.Medium} {
    font-size: 6rem;
  }
  sup {
    position: absolute;
    top: -1rem;
    left: -0.85rem;
    font-size: 1.5rem;
    vertical-align: text-top;
    @media ${MediaQuery.Medium} {
      top: -1.6rem;
      left: -1.4rem;
      font-size: 2.5rem;
    }
  }
`

const PerMonth = styled.p`
  font-size: 1.2rem;
  @media ${MediaQuery.Medium} {
    font-size: 1.5rem;
  }
`

const CostPerYear = styled.p`
  font-size: 1.2rem;
  @media ${MediaQuery.Medium} {
    font-size: 1.5rem;
  }
`

const AnnualSavings = styled.p`
  font-size: 1.4rem;
  margin: 20px 0;
  font-weight: bold;
  text-transform: uppercase;
  @media ${MediaQuery.Medium} {
    font-size: 1.8rem;
  }
`

const Description = styled.p`
  font-size: 1.2rem;
  @media ${MediaQuery.Medium} {
    font-size: 1.5rem;
  }
`

const Bottom = styled.div`
  margin: 0 0 10px 0;
`

const CTA = styled(ButtonSignup)`
  /* background-color: #fff; */
  /* color: ${Colors.blue}; */
  width: 90%;
  margin: 20px auto;
  @media ${MediaQuery.Medium} {
    width: 580px;
    margin: 40px auto;
  }
  @media ${MediaQuery.Large} {
    margin: 40px 0;
  }
`

const StyledDownloadButtons = styled(DownloadButtons)`
  /* justify-content: space-evenly; */
  /* margin: 0 auto; */
  @media ${MediaQuery.Large} {
    justify-content: center;
  }
`

const PercentOff = styled.div`
  margin: 10px 0;
  display: inline-block;
  border-radius: 10px;
  padding: 8px 20px;
  background: #29c6c1;
  p {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    color: #fff;
  }
  /* justify-content: space-evenly; */
  /* margin: 0 auto; */
  /* @media ${MediaQuery.Large} {
    justify-content: center;
  } */
`

interface Props {
  image: string
  imageComponent?: React.ReactNode
  title: string
  description: string
  monthlyCost: Cost
  annualCost: Cost
  visibleCost: Cost
  savings?: number
  packageType: string
  numericPrice: number
  maxNumericPrice: number
}

const PlanCard: React.FC<Props> = (props) => {
  const {
    image,
    imageComponent,
    annualCost,
    description,
    visibleCost,
    monthlyCost,
    title,
    numericPrice,
    maxNumericPrice,
    packageType,
    savings
  } = props

  const discount = calcDiscount(numericPrice, maxNumericPrice, packageType)

  return (
    <Card {...props}>
      {/* <ImageWrap>
        <Image src={image} alt={title} />
      </ImageWrap> */}
      {imageComponent}
      <Content>
        <div className="content-wrap">
          <StyledH1>{title}</StyledH1>

          <Price {...visibleCost} trailingText=""></Price>

          <Bottom>
            {/* {monthlyCost.dollars !== STANDARD_PLAN_PRICE && (
              <AnnualSavings>Save ${savings} / year</AnnualSavings>
            )} */}
            <Description>{description}</Description>
            {/* <CostPerYear>
              ${annualCost.dollars}.{annualCost.cents} / year
            </CostPerYear> */}

            {discount !== 0 && (
              <PercentOff>
                <p>{discount}&#37; OFF</p>
              </PercentOff>
            )}

            <FreeTrialWrap>
              <ButtonFreeTrial />
            </FreeTrialWrap>
          </Bottom>
          <StyledDownloadButtons />
        </div>
      </Content>
    </Card>
  )
}

export default PlanCard
